import { useIsLoggedIn } from '@emico-hooks/login-token'
import * as Sentry from '@sentry/react'
import { UrlRewriteEntityTypeEnum } from 'components/src/graphql/schema.generated'
import PageWrapper from 'components/src/layout/PageWrapper'
import NotFoundPage from 'components/src/NotFoundPage'
import paths from 'components/src/paths'
import PageLoader from 'components/src/presentation/PageLoader'
import { checkoutRoutes } from 'components/src/Routing'
import { omitAuthRoutes } from 'components/src/utils/omitAuthRoutes'
import { useResolveUrl } from 'components/src/utils/resolveUrl'
import * as React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route)

type PathedRoute = React.ComponentProps<typeof Route> & { path: string }
interface OwnProps {
    routes: PathedRoute[]
}

type Props = OwnProps

const ALLOWED_ROUTES = [
    paths.storeLocator,
    paths.logout,
    paths.login,
    paths.register,
    paths.registerAddress,
    paths.forgotPassword,
    paths.setPassword,
    paths.activateAccount,
]

const DefinedRoute = ({ route }: { route: PathedRoute }) => {
    const isLoggedIn = useIsLoggedIn() || omitAuthRoutes()
    // when you hit a disallowed path, kick to login
    if (!isLoggedIn && !ALLOWED_ROUTES.includes(route.path)) {
        return <Redirect to={paths.login} />
    }

    return <SentryRoute {...route} />
}

const DynamicRoute = ({ route }: { route: PathedRoute }) => {
    const { pathname } = useLocation()
    const { data: resolvedPage, loading, error } = useResolveUrl(pathname)
    const isLoggedIn = useIsLoggedIn() || omitAuthRoutes()

    if (error) {
        return <NotFoundPage />
    }

    if (!resolvedPage && loading) {
        return (
            <PageWrapper pageType="N/A">
                <PageLoader fullScreen reason="Resolving url..." />
            </PageWrapper>
        )
    }

    if (!resolvedPage) {
        return <NotFoundPage />
    }

    if (
        resolvedPage.type === UrlRewriteEntityTypeEnum.PRISMIC ||
        resolvedPage.type === UrlRewriteEntityTypeEnum.CMS_PAGE
    ) {
        // Pages defined in prismic can have an authRequired flag.
        // For those pages, a login is required.
        // Magento CMS pages are always accessible without login.
        return !isLoggedIn &&
            (resolvedPage.authRequired || pathname === paths.home) ? (
            <Redirect to={paths.login} />
        ) : (
            <SentryRoute {...route} />
        )
    } else {
        // For all other page types, like product pages, category pages, etc.
        // a login is required. Also, these pages require a valid address
        // for some unknown reason.
        return !isLoggedIn ? (
            <Redirect to={paths.login} />
        ) : (
            <SentryRoute {...route} />
        )
    }
}

const AppRouter = ({ routes }: Props) => (
    <Switch>
        {checkoutRoutes.map((route) => (
            <SentryRoute key={route.path} {...route} />
        ))}
        {routes.map((route) => (
            <SentryRoute
                key={route.path}
                path={route.path}
                render={() =>
                    route.path === paths.home ? (
                        <DynamicRoute route={route} />
                    ) : (
                        <DefinedRoute route={route} />
                    )
                }
            />
        ))}
    </Switch>
)

export default AppRouter
