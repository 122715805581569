import { Picture, Image } from '@emico-react/image'
import { JbfoLoginPage } from 'components/src/graphql/schema.generated'
import Container from 'components/src/layout/Container'
import PageWrapper from 'components/src/layout/PageWrapper'
import breakpoints from 'components/src/theme/breakpoints'
import Heading from 'components/src/typography/Heading'
import usePrismicSinglePageLogin from 'components/src/usePrismicSinglePageLogin'
import Helmet from 'react-helmet'

import styles from './AccountLoginPageLayout.module.scss'
import { Faq } from './Faq'
import LoginContent from './LoginContent'

const AccountLoginPageLayout = () => {
    const { data: prismicPage } =
        usePrismicSinglePageLogin<JbfoLoginPage>('jbfo-login')

    if (!prismicPage) {
        return null
    }

    const faqSubject = prismicPage.body?.find(
        (body) => body.__typename === 'JbfoLoginPageBodyFaqSubject',
    )

    return (
        <PageWrapper pageType="user" contentClassName={styles.pageWrapper}>
            <Helmet>
                {prismicPage?.seoTitle && (
                    <title>{prismicPage?.seoTitle}</title>
                )}
                {prismicPage?.seoDescription && (
                    <meta
                        name="description"
                        content={prismicPage?.seoDescription}
                    />
                )}
                {prismicPage?.seoKeywords && (
                    <meta name="keywords" content={prismicPage?.seoKeywords} />
                )}
            </Helmet>

            <div className={styles.desktopHeader}>
                {prismicPage?.backgroundImage?.url && (
                    <Picture
                        breakpoints={{
                            [breakpoints.xs]: {
                                url:
                                    prismicPage.mobileImage?.url ??
                                    prismicPage.backgroundImage.url,
                                width: 400,
                                height: undefined,
                            },
                            [breakpoints.md]: {
                                url: prismicPage.backgroundImage.url,
                                width: 1024,
                                height: undefined,
                            },
                            [breakpoints.lg]: {
                                url: prismicPage.backgroundImage.url,
                                width: 2000,
                                height: undefined,
                            },
                        }}
                        lazy
                        alt=""
                        className={styles.desktopImageWrapper}
                    />
                )}
                <div className={styles.desktopContentWrapper}>
                    {prismicPage && <LoginContent prismicPage={prismicPage} />}
                </div>
            </div>

            <div className={styles.mobileHeader}>
                <Container noPadding>
                    {prismicPage &&
                        prismicPage.backgroundImage?.url &&
                        prismicPage.mobileImage?.url && (
                            <>
                                {prismicPage && prismicPage.title && (
                                    <div className={styles.topHeadingMobile}>
                                        <Container>
                                            <Heading
                                                variant="h2"
                                                element="h1"
                                                color={
                                                    prismicPage?.backgroundImage
                                                        ?.url
                                                        ? 'light'
                                                        : 'dark'
                                                }
                                            >
                                                {prismicPage.title}
                                            </Heading>
                                        </Container>

                                        {prismicPage.backgroundImage?.url && (
                                            <Image
                                                url={
                                                    prismicPage.mobileImage
                                                        ?.url ??
                                                    prismicPage.backgroundImage
                                                        .url ??
                                                    ''
                                                }
                                                lazy
                                                width={400}
                                                className={
                                                    styles.imageWrapperMobile
                                                }
                                                alt=""
                                            />
                                        )}
                                    </div>
                                )}

                                <LoginContent prismicPage={prismicPage} />
                            </>
                        )}
                </Container>
            </div>

            <Faq faqSubject={faqSubject} />
            {prismicPage?.logos && prismicPage?.logos.length > 0 && (
                <div className={styles.logoContainer}>
                    {prismicPage.logos.map(
                        (logo) =>
                            logo?.logoImage?.url && (
                                <Image
                                    key={logo.logoImage.url}
                                    url={logo.logoImage.url ?? ''}
                                    alt={logo.logoImage.alt ?? ''}
                                    sizes={{
                                        sm: 150,
                                        md: 230,
                                        lg: 230,
                                    }}
                                    lazy
                                />
                            ),
                    )}
                </div>
            )}
        </PageWrapper>
    )
}

export default AccountLoginPageLayout
